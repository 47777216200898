var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "card",
        {
          attrs: { title: _vm.$t("sector.title") },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.createSector()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "", small: "" } }, [
                        _vm._v("fa fa-plus"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("actions.create")) + " "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("grid-view", {
            ref: "grid",
            attrs: {
              headers: _vm.headers,
              endpoint: "sector",
              model: "sector",
            },
            scopedSlots: _vm._u([
              {
                key: "item._actions",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.viewSector(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-eye"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.editSector(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-pencil"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteSector(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v("fa fa-trash"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.name",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(_vm.translate(item.name)) + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.editDialog,
            callback: function ($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog",
          },
        },
        [
          _vm.editDialog
            ? _c("Form", {
                attrs: { sectorId: _vm.editedSectorId },
                on: { save: _vm.onDialogSave },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }